import React from "react";

const HistoryAlarmContainer = Loader.loadBusinessComponent(
  "MonitorHistoryAlarm",
  "HistoryAlarmContainer"
);

const searchDataInit = {
  startTime: undefined,
  endTime: undefined,
  installationSites: undefined,
  alarmOperationType: undefined,
  sort: ["captureTime|desc"],
  taskIds: undefined,
  offset: 0,
  limit: 24
};

const searchList = [
  "AlarmTimeTypeSelect",
  "AlarmSiteScreening",
  "AlarmTimeRadio"
]

const PrivateNetHistoryAlarm = () => (
  <HistoryAlarmContainer 
    libType={4}
    moduleName='privateNetHistory'
    detailModuleName='privateNetDetail'
    searchDataInit={searchDataInit}
    searchList={searchList}
    cardType='ForeignCard'
    cardStyle={{
      itemWidth: 174,
      itemHeight: 324,
      pdWidth: 120,
    }}
    taskType='101504'
    showSearchInput={false}
  />
)

export default PrivateNetHistoryAlarm;
